
.homeColumn {
  margin: 4em auto;
  width: 70%;
}

/* For mobile phones */
@media only screen and (max-width:620px) {
  .homeColumn {
    width: 80%;
  }
}

.homeColumn > .homeSection {
  margin: 3em 0em;
}

.homeColumn h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 2em;
  letter-spacing: 0.02em;
  margin: 0;
  color: #333333;
  text-align: center;
}

.homeSection ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.homeSection li {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0.2em;
  text-align: center;
}

.homeSection p {
  text-align: center;
}