
.header {
  text-align: center;
  margin: 5em auto 3em;
}

.header h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 4em;
  line-height: 0.8em;
  letter-spacing: 0.05em;
  color: #3B5E24;
  padding: 0;
  margin: 0;
}

.header h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 2em;
  color: #3B5E24;
  padding: 0;
  margin: 1em;
}
